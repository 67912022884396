<template>
    <div>
        <ConfirmacaoModal
            v-if="mostrarModalConfirmacao"
            :showDialog="mostrarModalConfirmacao"
            :tituloModal="tituloModalConfirmacao"
            :textoModal="textoModalConfirmacao"
            :btnConfirmarEmit="btnConfirmarModalConfirmacao"
            :esconderCancelar="esconderCancelar"
            :btnConfirmar="btnConfirmarLabel"
            @invalidarInstrumento="invalidarInstrumento"
            @alterarAgrupador="alterarAgrupador"
            @scrollAnexoProtesto="scrollAnexoProtesto"
            @fecharModal="
                mostrarModalConfirmacao = false;
                esconderCancelar = false;
            "
        />
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Informações do Título:
                </p>
                <v-row>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.cd_status"
                            label="Código do Status"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="getDescSituacaoComputed"
                            label="Descrição da Situação"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="2" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.id_titulo"
                            label="Id"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.ds_nosso_numero"
                            label="Nosso Número"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.ds_numero_titulo"
                            label="Número do Título"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="1">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.nu_parcela"
                            label="Parcela"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="formatDataEmissaoInline"
                            label="Data de Emissão"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="formatDataVencimentoInline"
                            label="Data de Vencimento"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.tp_declaracao"
                            label="Tipo Declaração"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.tp_endosso"
                            label="Endosso"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.vl_saldo_protesto_formatado"
                            label="Valor Protestado"
                            prefix="R$"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.vl_titulo_formatado"
                            label="Valor Total do Título"
                            prefix="R$"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" lg="1">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            :value="titulo.tp_aceite || ' '"
                            label="Aceite"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="1" md="1" lg="2">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            :value="titulo.cd_falimentar || ' '"
                            label="Falimentar"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="1">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="titulo.cd_especie_titulo"
                            label="Espécie"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="6" lg="4">
                        <div class="d-flex">
                            <v-text-field
                                v-if="!usuarioAdmin"
                                outlined
                                hide-details
                                dense
                                :value="
                                    getDescritivoAgrupador(
                                        $store,
                                        titulo.ds_agrupador
                                    )
                                "
                                label="Agrupador"
                                required
                                :readonly="!showActionSalvar"
                            ></v-text-field>
                            <v-select
                                v-else
                                outlined
                                dense
                                color="success"
                                :disabled="
                                    loadingAgrupador || !editandoAgrupador
                                "
                                :loading="loadingAgrupador"
                                :items="agrupadores"
                                v-model="titulo.ds_agrupador"
                                label="Agrupador"
                                required
                                item-text="nm_agrupador"
                                item-value="ds_agrupador"
                                data-cy="tituloAgrupador"
                                hide-details
                            />
                            <div v-if="usuarioAdmin">
                                <div v-if="editandoAgrupador" class="d-flex">
                                    <v-btn
                                        x-small
                                        elevation="0"
                                        fab
                                        class="my-1 ml-2 mr-1 pa-2"
                                        @click="modalAlterarAgrupador"
                                        :loading="loadingAgrupador"
                                        data-cy="salvarAgrupador"
                                    >
                                        <v-icon>mdi mdi-check</v-icon>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        elevation="0"
                                        fab
                                        class="my-1 mr-2 ml-1 pa-2"
                                        @click="cancelarEdicaoAgrupador"
                                        :loading="loadingAgrupador"
                                    >
                                        <v-icon>mdi mdi-close</v-icon>
                                    </v-btn>
                                </div>
                                <v-btn
                                    v-else
                                    x-small
                                    elevation="0"
                                    fab
                                    class="my-1 mx-2 pa-2"
                                    @click="editarAgrupador"
                                    data-cy="editarAgrupador"
                                >
                                    <v-icon>mdi mdi-pencil </v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6" md="4" lg="4">
                        <v-text-field
                            outlined
                            dense
                            hide-details
                            v-model="valorEmolumento"
                            label="Valor estimado de Emolumentos"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        v-if="titulo.vl_repassado != null"
                        cols="12"
                        sm="2"
                        md="2"
                    >
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.vl_repassado"
                            label="Valor Repasse"
                            required
                            prefix="R$"
                            :readonly="!showActionSalvar"
                            hint="Valores repassados pelo cartório"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col
                        v-if="[101, 107, 103, 121].includes(titulo.cd_status)"
                        cols="12"
                        sm="2"
                        md="2"
                    >
                        <v-text-field
                            outlined
                            dense
                            :value="titulo.vl_custas_formatado || '0,00'"
                            label="Valor Custas"
                            required
                            prefix="R$"
                            :readonly="!showActionSalvar"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Informações do Devedor <b>(Sacado)</b>:
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="9" md="9">
                        <v-text-field
                            v-if="checarNomeDevedorCRA(titulo)"
                            outlined
                            dense
                            v-model="titulo.nm_devedor_cra"
                            label="Devedor Ajustado"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                        <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="titulo.nm_devedor"
                            label="Devedor"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9" md="3">
                        <v-text-field
                            outlined
                            dense
                            v-model="formatDocumentoSacadoInline"
                            label="CNPJ/CPF"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="9" md="8">
                        <v-text-field
                            v-if="checarEnderecoDevedorCRA(titulo)"
                            outlined
                            dense
                            v-model="titulo.ds_endereco_devedor_cra"
                            label="Endereço Ajustado"
                            required
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                        <v-text-field
                            v-else
                            outlined
                            dense
                            v-model="titulo.ds_endereco_devedor"
                            label="Endereço"
                            required
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.ds_bairro_devedor"
                            label="Bairro"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_cidade_devedor"
                            label="Cidade"
                            required
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-model="formatCEPDevedorInline"
                            label="CEP"
                            required
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.cd_uf_devedor"
                            label="UF"
                            required
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.ds_email_devedor"
                            label="E-mail do devedor"
                            required
                            readonly
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3" md="2">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nu_telefone_devedor"
                            label="Telefone do devedor"
                            required
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <CardCoodevedores
            v-if="titulo.nu_quantidade_devedores > 1"
            :titulo="titulo"
            :loading="loading"
            :showInModal="true"
            @atualizarTitulo="exibirDetalhe"
        />
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Apontamento
                    <v-btn
                        data-cy="btnEditarApontamento"
                        v-if="superUsuario && usuarioRecuperi"
                        :loading="loadingMunicipios"
                        :disabled="loadingMunicipios"
                        class="ma-2 pa-2"
                        @click="ativaBotaoCartorio"
                        >Editar</v-btn
                    >
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-show="superUsuario && usuarioRecuperi"
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                elevation="0"
                                fab
                                :loading="loadingMunicipios"
                                :disabled="loadingMunicipios"
                                class="ma-2 pa-2"
                                @click="ressincronizarMunicipios"
                            >
                                <v-icon> mdi-sync </v-icon>
                            </v-btn>
                        </template>
                        <span
                            >Ressincronizar listagem de
                            comarcas/municípios</span
                        >
                    </v-tooltip>
                </p>
                <v-row class="">
                    <v-col cols="12" sm="3" md="3">
                        <v-text-field
                            outlined
                            dense
                            :filled="desabilitaCartorios"
                            :disabled="desabilitaCartorios"
                            v-model="titulo.apontamento_nu_protocolo"
                            label="Protocolo do Cartório"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                        <v-text-field
                            outlined
                            dense
                            v-model="formatDatacartorioInline"
                            label="Data de Apontamento"
                            required
                            :filled="desabilitaCartorios"
                            :disabled="desabilitaCartorios"
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                        <v-card class="pa-2" v-show="editarCartorio">
                            <v-card-text class="ma-2 pa-2">
                                <v-icon small class="ma-2"
                                    >mdi-home-city</v-icon
                                >
                                Número do Protocolo
                            </v-card-text>
                            <v-row>
                                <v-col lg="12">
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="novoProtocolo"
                                        label="Protocolo do Cartório"
                                        required
                                        maxlength="10"
                                        :onkeypress="
                                            permitirLetrasNumeros(false)
                                        "
                                    ></v-text-field>

                                    <v-card
                                        class="pa-2 mt-4"
                                        style="box-shadow: none"
                                        v-show="editarCartorio"
                                    >
                                        <v-card-text class="ma-2 pa-2">
                                            <v-icon small class="ma-2"
                                                >mdi-home-city</v-icon
                                            >
                                            Data do Apontamento
                                        </v-card-text>
                                        <v-row>
                                            <v-col lg="12">
                                                <v-menu
                                                    v-model="menu2"
                                                    :close-on-content-click="
                                                        false
                                                    "
                                                    :nudge-right="40"
                                                    transition="scale-transition"
                                                    offset-y
                                                    offset-x
                                                    min-width="auto"
                                                >
                                                    <template
                                                        v-slot:activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-text-field
                                                            outlined
                                                            dense
                                                            v-model="
                                                                dataDigitavel
                                                            "
                                                            label="Clique para selecionar"
                                                            required
                                                            locale="pt-br"
                                                            v-maska="
                                                                '##/##/####'
                                                            "
                                                            prepend-icon="mdi-calendar"
                                                            hint="Formato: dd/mm/AAAA"
                                                            class="parcelas"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            @focus="setFocus"
                                                            @keyup.enter="
                                                                validarDataDigitada(
                                                                    true,
                                                                    $event
                                                                )
                                                            "
                                                            @blur="
                                                                validarDataDigitada()
                                                            "
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                        v-model="data"
                                                        locale="pt-br"
                                                        color="primary"
                                                        @input="
                                                            selecionarData(data)
                                                        "
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" sm="3" md="9">
                        <v-row>
                            <v-col cols="12" sm="3" md="6" class="pb-0">
                                <v-text-field
                                    outlined
                                    dense
                                    :filled="desabilitaCartorios"
                                    :disabled="desabilitaCartorios"
                                    v-model="formatNmCartorio"
                                    label="Cartório"
                                    required
                                    :readonly="!showActionSalvar"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="3" md="6" class="pb-0">
                                <v-text-field
                                    v-model="cartorioEndereco"
                                    outlined
                                    dense
                                    label="Endereço do Cartório"
                                    required
                                    :filled="desabilitaCartorios"
                                    :disabled="desabilitaCartorios"
                                    :readonly="!showActionSalvar"
                                    class="parcelas"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="4" class="pt-0">
                                <v-text-field
                                    outlined
                                    dense
                                    v-model="formatMunicipioApontamento"
                                    label="Município de Apontamento"
                                    required
                                    :filled="desabilitaCartorios"
                                    :disabled="desabilitaCartorios"
                                    :readonly="!showActionSalvar"
                                    class="parcelas"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="5"
                                class="pt-0 d-flex flex-row"
                            >
                                <v-text-field
                                    v-model="cartorioEmail"
                                    outlined
                                    dense
                                    label="E-mail do Cartório"
                                    required
                                    :filled="desabilitaCartorios"
                                    :disabled="desabilitaCartorios"
                                    :readonly="!showActionSalvar"
                                    class="parcelas"
                                ></v-text-field>
                                <v-btn
                                    v-show="cartorioEmail"
                                    icon
                                    :href="`mailto:${this.cartorioEmail}`"
                                    target="_blank"
                                >
                                    <v-icon class="success--text"
                                        >mdi-email</v-icon
                                    >
                                </v-btn>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="3"
                                md="3"
                                class="pt-0 d-flex flex-row"
                            >
                                <v-text-field
                                    v-model="telefoneCartorio"
                                    outlined
                                    dense
                                    label="Telefone do Cartório"
                                    required
                                    :filled="desabilitaCartorios"
                                    :disabled="desabilitaCartorios"
                                    :readonly="!showActionSalvar"
                                    class="parcelas"
                                ></v-text-field>
                                <v-btn
                                    v-show="cartorioTelefone"
                                    icon
                                    :href="`tel:${this.cartorioTelefone}`"
                                >
                                    <v-icon class="success--text"
                                        >mdi-phone</v-icon
                                    >
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card class="pa-2" v-show="editarCartorio">
                            <v-card-text class="ma-2 pa-2">
                                <v-icon small class="ma-2"
                                    >mdi-home-city</v-icon
                                >
                                Selecione o novo Município
                            </v-card-text>
                            <v-row>
                                <v-col lg="1">
                                    <v-progress-circular
                                        v-show="loadingMunicipios"
                                        color="primary"
                                        small
                                        indeterminate
                                        class="ml-5"
                                    ></v-progress-circular>
                                    <v-icon
                                        class="ml-5"
                                        large
                                        v-show="iconLateral"
                                        >mdi-chevron-right</v-icon
                                    >
                                </v-col>
                                <v-col lg="8">
                                    <v-autocomplete
                                        v-model="valueMunicipios"
                                        :items="itensMunicipios.municipios"
                                        dense
                                        :loading="loadingMunicipios"
                                        :disabled="loadingMunicipios"
                                        @input="buscaCartorio"
                                        item-text="nm_estado_municipio"
                                        item-value="cd_municipio_completo"
                                        outlined
                                        label="Selecione o Município"
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col lg="3">
                                    <v-autocomplete
                                        v-model="valueMunicipios"
                                        :items="itensMunicipios.municipios"
                                        dense
                                        outlined
                                        item-text="cd_ibge_comarca"
                                        item-value="cd_municipio_completo"
                                        @input="buscaCartorio"
                                        label="Comarca"
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-card>
                        <v-card
                            class="pa-2 mt-4"
                            v-show="editarCartorioComarca"
                        >
                            <v-card-text class="ma-2 pa-2">
                                <v-icon small class="ma-2"
                                    >mdi-home-city</v-icon
                                >
                                Selecione o novo Cartório
                            </v-card-text>
                            <v-row>
                                <v-col lg="1">
                                    <v-progress-circular
                                        v-show="loadingCartorios"
                                        color="primary"
                                        indeterminate
                                        class="ml-5"
                                    ></v-progress-circular>
                                    <v-icon
                                        class="ml-5"
                                        large
                                        v-show="iconLateralCartorios"
                                        >mdi-chevron-right</v-icon
                                    >
                                </v-col>
                                <v-col lg="8">
                                    <v-autocomplete
                                        v-model="valueCartorios"
                                        :items="itemsCartorios"
                                        dense
                                        no-data-text="Nenhum cartório encontrado para esse município"
                                        item-text="nm_cartorio"
                                        item-value="nu_cartorio"
                                        outlined
                                        label="Selecione o Cartório"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col lg="3">
                                    <v-select
                                        v-model="valueCartorios"
                                        :items="itemsCartorios"
                                        dense
                                        outlined
                                        item-text="nu_cartorio"
                                        item-value="nu_cartorio"
                                        label="Número do Cartório"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-col
                    class="text-right px-0 pt-3 pb-0"
                    v-show="editarCartorio"
                >
                    <v-btn
                        class="white--text"
                        color="error"
                        @click="fechaDialogComarca"
                        >Cancelar</v-btn
                    >
                    <v-btn
                        class="ml-3 white--text"
                        @click="salvarDadosComarca"
                        color="green"
                        >Salvar Alterações
                    </v-btn>
                </v-col>
            </v-container>
        </v-card>
        <v-card class="ma-3 px-3 pt-3 pb-6" :loading="loadingInstrumento">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Instrumento de Protesto
                </p>
                <v-row v-if="!instrumentoProtesto" class="">
                    <v-col
                        sm="12"
                        md="12"
                        lg="12"
                        class="d-flex justify-center"
                    >
                        <v-alert
                            prominent
                            :icon="getIconeAlerta()"
                            shaped
                            :type="getTipoAlerta()"
                        >
                            {{ getTextoAlerta() }}
                            <v-btn
                                v-if="permitirSolicitarInstrumento()"
                                @click="solicitaInstrumentoProt"
                                color="#46a6f5"
                                fab
                                dark
                                small
                                class="white--text ml-2"
                            >
                                <v-icon class="ma-2"
                                    >mdi-arrow-top-right-bold-box-outline</v-icon
                                >
                            </v-btn>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row v-else class="mt-2 d-flex align-center">
                    <v-col cols="12" sm="4" md="4" class="py-0">
                        <v-text-field
                            outlined
                            dense
                            v-model="instrumentoProtesto.ds_anexo_nome"
                            label="Nome Anexo"
                            required
                            readonly
                            hide-details
                        ></v-text-field>
                    </v-col>
                    <v-btn
                        class="ml-3 white--text"
                        @click="baixarInstrumentos"
                        :loading="baixandoInstrumento"
                        color="green"
                        width="120px"
                        >BAIXAR
                    </v-btn>
                    <v-btn
                        v-if="superUsuario"
                        class="ml-3 white--text"
                        @click="modalInvalidarInstrumento"
                        :loading="baixandoInstrumento"
                        color="orange darken-3"
                        width="120px"
                        >INVALIDAR
                    </v-btn>
                </v-row>
            </v-container>
        </v-card>
        <v-card
            v-if="mostrarAnexoProtesto() && verificaPermissaoAnexo()"
            :loading="loadingAnexo"
            class="ma-3 px-3 pt-3 pb-6"
        >
            <v-container>
                <p class="title" ref="sessaoAnexo">
                    <v-icon class="ma-2">mdi-information-outline</v-icon>
                    Anexo Arquivo de Protesto
                </p>
                <v-row
                    v-if="anexoProtesto != null"
                    class="mt-2 d-flex align-center"
                >
                    <v-col cols="12" sm="4" md="4" class="py-0">
                        <v-text-field
                            v-if="!arquivosSelecionadosTitulo"
                            outlined
                            dense
                            v-model="anexoProtesto.ds_anexo_nome"
                            label="ID Anexo"
                            required
                            readonly
                            hide-details
                        ></v-text-field>
                        <div v-else>
                            <span>
                                Voce tem certeza que deseja substituir o anexo?
                            </span>
                            {{ formatarNomesArquivos() }}
                            <v-tooltip
                                v-if="
                                    arquivosSelecionadosTitulo &&
                                        arquivosSelecionadosTitulo.length
                                "
                                top
                            >
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-icon
                                        class="ma-3"
                                        :color="
                                            tamanhoArquivosValido(
                                                arquivosSelecionadosTitulo
                                            )
                                                ? 'green'
                                                : 'red'
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{
                                            tamanhoArquivosValido(
                                                arquivosSelecionadosTitulo
                                            )
                                                ? 'done'
                                                : 'error'
                                        }}
                                    </v-icon>
                                </template>
                                <span>
                                    {{
                                        tamanhoArquivosValido(
                                            arquivosSelecionadosTitulo
                                        )
                                            ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título'
                                            : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título'
                                    }}
                                </span>
                            </v-tooltip>
                        </div>
                    </v-col>
                    <div v-if="!arquivosSelecionadosTitulo">
                        <v-btn
                            class="white--text ml-3"
                            @click="baixarArquivoProtesto"
                            :loading="loadingAnexo"
                            color="green"
                            width="120px"
                            >BAIXAR
                        </v-btn>
                        <v-btn
                            v-if="titulo.cd_status == 220"
                            class="white--text ml-3"
                            @click="
                                document
                                    .getElementById('pdfFileInputUpdate')
                                    .click()
                            "
                            :loading="loadingAnexo || loading"
                            color="blue"
                            width="auto"
                            >substituir
                            <v-icon class="mx-2"
                                >mdi-file-replace-outline</v-icon
                            >
                        </v-btn>
                        <v-file-input
                            v-model="arquivosSelecionadosTitulo"
                            :accept="'application/pdf, application/zip'"
                            style="display: none"
                            hide-input
                            prepend-icon=""
                            multiple
                            id="pdfFileInputUpdate"
                            @change="$forceUpdate()"
                        ></v-file-input>
                    </div>
                    <div v-else>
                        <v-btn
                            class="white--text ml-3"
                            @click="arquivosSelecionadosTitulo = null"
                            color="red"
                            width="auto"
                            >cancelar
                            <v-icon class="ml-2">mdi-close</v-icon>
                        </v-btn>
                        <v-btn
                            class="white--text ml-3"
                            @click="substituirAnexo()"
                            :loading="loadingAnexo || loading"
                            color="green"
                            width="auto"
                            >confirmar
                            <v-icon class="ml-2">mdi-check</v-icon>
                        </v-btn>
                    </div>
                </v-row>
                <div v-else>
                    <v-row class="ma-0">
                        <v-col
                            cols="6"
                            xs="6"
                            sm="6"
                            md="7"
                            lg="7"
                            class="font-weight-bold"
                        >
                            Arquivo(s) anexado(s)
                        </v-col>
                        <v-col
                            cols="6"
                            xs="6"
                            sm="6"
                            md="5"
                            lg="5"
                            class="font-weight-bold"
                        >
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col
                            cols="6"
                            xs="6"
                            sm="6"
                            md="7"
                            lg="7"
                            class="pa-3 d-flex justify-start align-center"
                        >
                            {{ formatarNomesArquivos() }}
                            <v-tooltip
                                v-if="
                                    arquivosSelecionadosTitulo &&
                                        arquivosSelecionadosTitulo.length
                                "
                                top
                            >
                                <template
                                    v-slot:activator="{
                                        on,
                                        attrs
                                    }"
                                >
                                    <v-icon
                                        class="ma-3"
                                        :color="
                                            tamanhoArquivosValido(
                                                arquivosSelecionadosTitulo
                                            )
                                                ? 'green'
                                                : 'red'
                                        "
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        {{
                                            tamanhoArquivosValido(
                                                arquivosSelecionadosTitulo
                                            )
                                                ? 'done'
                                                : 'error'
                                        }}
                                    </v-icon>
                                </template>
                                <span>
                                    {{
                                        tamanhoArquivosValido(
                                            arquivosSelecionadosTitulo
                                        )
                                            ? 'Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título'
                                            : 'A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título'
                                    }}
                                </span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row class="ma-0">
                        <v-col>
                            <span v-if="!anexoProtesto" class="text-grey">
                                <strong>Arquivos aceitos:</strong> PDF ou ZIP
                                com tamanho inferior à 4,5MB. Certifique-se que
                                os arquivos não possuam carecteres especiais
                            </span>
                        </v-col>
                        <v-col
                            cols="4"
                            xs="4"
                            sm="4"
                            md="3"
                            lg="3"
                            class="pa-0 d-flex justify-end align-center"
                        >
                            <v-btn
                                class="white--text ml-3"
                                @click="
                                    document
                                        .getElementById('pdfFileInput')
                                        .click()
                                "
                                color="green"
                                :disabled="anexoProtesto != null"
                                :loading="loadingAnexo || loading"
                                >SELECIONAR ANEXO(s)
                            </v-btn>
                            <v-btn
                                v-if="!pendenteSelecionarArquivos()"
                                class="white--text ml-3"
                                @click="anexarArquivoProtesto()"
                                color="green"
                                :loading="loadingAnexo || loading"
                                :disabled="
                                    !tamanhoArquivosValido(
                                        arquivosSelecionadosTitulo
                                    )
                                "
                                >ENVIAR
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-file-input
                        v-model="arquivosSelecionadosTitulo"
                        :accept="'application/pdf, application/zip'"
                        style="display: none"
                        hide-input
                        prepend-icon=""
                        multiple
                        id="pdfFileInput"
                        @change="$forceUpdate()"
                    ></v-file-input>
                </div>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Apresentante
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="this.$store.getters.nomeApresentante"
                            label="Razão Social"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.cd_apresentante"
                            label="Código"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3"> </v-col>
                    <v-col cols="12" sm="2" md="3"> </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon>Sacador
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_sacador"
                            label="Nome do Sacador"
                            required
                            :readonly="!showActionSalvar"
                            class="parcelas"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="formatDocumentoApresentanteInline"
                            v-mask="['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                            label="CNPJ/CPF"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="4"> </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="ma-3 pa-3" :loading="loading">
            <v-container>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon>Cedente
                </p>
                <v-row class="mb-n8">
                    <v-col cols="12" sm="2" md="4">
                        <v-text-field
                            outlined
                            dense
                            v-model="titulo.nm_cedente_favorecido"
                            label="Nome Cedente Favorecido"
                            required
                            :readonly="!showActionSalvar"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="4"> </v-col>
                    <v-col cols="12" sm="2" md="4"> </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card
            class="ma-3 pa-3"
            :loading="loading"
            v-if="superUsuario && usuarioRecuperi"
        >
            <v-container>
                <p
                    v-if="
                        superUsuario &&
                            usuarioRecuperi &&
                            titulo.cd_status === 102
                    "
                    class="title"
                >
                    <v-icon class="ma-2">mdi-information-outline</v-icon>Ofertas
                    Protestado
                </p>
                <template>
                    <v-alert
                        v-if="
                            superUsuario &&
                                usuarioRecuperi &&
                                titulo.cd_status === 102
                        "
                        :type="alertTypeOfertas"
                    >
                        {{ alertInfoOfertas }}
                    </v-alert>
                </template>
                <p class="title">
                    <v-icon class="ma-2">mdi-information-outline</v-icon
                    >Mensageria
                </p>
                <template>
                    <v-alert
                        v-if="superUsuario && usuarioRecuperi"
                        :type="alertTypeEmail"
                    >
                        {{ alertInfoEmail }}</v-alert
                    >
                    <v-alert
                        v-if="superUsuario && usuarioRecuperi"
                        :type="alertTypeCel"
                    >
                        {{ alertInfoCel }}</v-alert
                    >
                    <v-alert
                        v-if="superUsuario && usuarioRecuperi"
                        :type="alertTypeCelCheck"
                    >
                        {{ alertInfoCelCheck }}</v-alert
                    >
                    <v-alert
                        v-if="superUsuario && usuarioRecuperi"
                        :type="habilitadoNotifica ? 'success' : 'info'"
                    >
                        {{
                            habilitadoNotifica
                                ? 'Notifica: o Apresentante está habilitado na Notifica'
                                : 'Notifica: o Apresentante não está habilitado na Notifica'
                        }}</v-alert
                    >
                </template>
            </v-container>
        </v-card>
        <v-btn v-show="showActionSalvar" class="mr-4">Salvar</v-btn>
    </div>
</template>

<script>
import * as Formatter from '@/helpers/Formatter';
import moment from 'moment';
import TituloService from '@/services/TituloService';
import emolumentosService from '@/services/EmolumentosService.js';
import CartorioService from '@/services/CartorioService';
import ConfirmacaoModal from '@/modals/ConfirmacaoModal';
import CardCoodevedores from '@/components/organisms/CardCoodevedores';
import Vue from 'vue';
import {
    getDataAtualDatePicker,
    permitirLetrasNumeros,
    validarFormatoData,
    getMensagemRequest,
    listarAgrupadores,
    getDescritivoAgrupador,
    montarObjetoAnexoProtesto,
    converterArquivoParaBase64
} from '@/helpers/Utilitarios';
import lodash from 'lodash';
import { mask } from 'maska';
import { eventFecharModal } from '../helpers/Utilitarios';

export default {
    name: 'DetalheTitulo',
    props: {
        titulo: {
            type: Object,
            required: true
        },
        setAtualizarTitulo: {
            type: Boolean,
            required: false
        },
        mostrarConfirmacao: {
            type: Boolean,
            required: false
        },
        habilitaCorrecao: {
            type: Boolean,
            required: false
        },
        showActionSalvar: Boolean
    },
    components: {
        ConfirmacaoModal,
        CardCoodevedores
    },
    data() {
        return {
            valorEmolumento: 0,
            emolumentosService: new emolumentosService(Vue.http, this.$store),
            document,
            tamanhoMaximoSomaArquivos: 5000000,
            arquivosSelecionadosTitulo: null,
            tituloModalConfirmacao: '',
            textoModalConfirmacao: '',
            btnConfirmarModalConfirmacao: '',
            btnConfirmarLabel: 'Confirmar',
            tituloService: new TituloService(Vue.http, this.$store),
            alertTypeEmail: 'info',
            alertInfoEmail: 'Verificação: em andamento...',
            alertTypeCel: 'info',
            alertInfoCel: 'Verificação: em andamento...',
            cartorioEndereco: '',
            cartorioEmail: '',
            cartorioTelefone: '',
            mostrarModalConfirmacao: false,
            esconderCancelar: false,
            custodia: false,
            usuarioPartner: false,
            usuarioRecuperi: false,
            data: null,
            dataDigitavel: null,
            menu2: null,
            superUsuario: false,
            editarCartorioComarca: false,
            loadingMunicipios: null,
            iconLateral: null,
            loadingCartorios: null,
            loading: false,
            baixandoInstrumento: false,
            iconLateralCartorios: null,
            novoProtocolo: null,
            dataApontamento: null,
            novoApontamento: null,
            desabilitaCartorios: false,
            editarCartorio: false,
            itemsCartorios: [],
            instrumentoProtesto: null,
            anexoProtesto: null,
            valueCartorios: null,
            valueMunicipios: null,
            emailCheck: false,
            stEnvioCheck: false,
            telefoneCheck: false,
            loadingInstrumento: false,
            loadingAnexo: false,
            solicitaInstrumento: false,
            editandoAgrupador: false,
            agrupadorTemp: null,
            loadingAgrupador: false
        };
    },
    watch: {
        setAtualizarTitulo(val) {
            if (val) {
                this.exibirDetalhe();
            }
        },
        mostrarConfirmacao(val) {
            if (val) {
                document.removeEventListener('keyup', this.eventFecharModal);
            } else {
                document.addEventListener('keyup', this.eventFecharModal);
            }
        },
        mostrarModalConfirmacao(val) {
            if (val) {
                document.removeEventListener('keyup', this.eventFecharModal);
            } else {
                document.addEventListener('keyup', this.eventFecharModal);
            }
        },
        habilitaCorrecao(val) {
            if (val) {
                document.removeEventListener('keyup', this.eventFecharModal);
            } else {
                document.addEventListener('keyup', this.eventFecharModal);
            }
        }
    },
    methods: {
        listarAgrupadores,
        getDescritivoAgrupador,
        montarObjetoAnexoProtesto,
        converterArquivoParaBase64,
        editarAgrupador() {
            this.editandoAgrupador = true;
            this.agrupadorTemp = lodash.cloneDeep(this.titulo.ds_agrupador);
        },
        getValorEmolumento() {
            this.emolumentosService
                .getEmolumentoEstimado({ id_titulo: this.titulo.id_titulo })
                .then(response => {
                    this.valorEmolumento = parseFloat(
                        response.vl_custas
                    ).toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    });
                })
                .catch(() => {
                    this.emitirSnackbar(
                        'error',
                        'Erro ao buscar valor do emolumento'
                    );
                });
        },

        cancelarEdicaoAgrupador() {
            this.editandoAgrupador = false;
            this.titulo.ds_agrupador = lodash.cloneDeep(this.agrupadorTemp);
        },
        async alterarAgrupador() {
            try {
                this.loadingAgrupador = true;
                this.mostrarModalConfirmacao = false;
                await this.tituloService.atualizarAgrupador(
                    this.titulo.id_titulo,
                    this.titulo.ds_agrupador
                );

                this.editandoAgrupador = false;
                this.emitirSnackbar(
                    'green',
                    'Agrupador de Título atualizado com Sucesso',
                    null
                );
                this.exibirDetalhe();
            } catch (error) {
                this.cancelarEdicaoAgrupador();
                const mensagem = this.getMensagemRequest(
                    error.body,
                    'Erro ao atualizar Agrupador de Título'
                );
                this.emitirSnackbar('error', mensagem, null);
            } finally {
                this.loadingAgrupador = false;
            }
        },
        solicitaInstrumentoProt() {
            if ([102, 123].includes(this.titulo.cd_status)) {
                this.loadingInstrumento = true;
                this.solicitaInstrumento = true;
                const tituloService = new TituloService(Vue.http, this.$store);
                let titulo = this.titulo.id_titulo;
                let cd_apresentante = this.titulo.cd_apresentante;
                if (this.titulo.cd_comprador) {
                    cd_apresentante = this.titulo.cd_comprador;
                }
                tituloService
                    .solicitaInstrumento(titulo, cd_apresentante)
                    .then(response => {
                        this.loadingInstrumento = false;
                        if (response.status == 200) {
                            this.titulo.st_solicita_instrumento = 1;
                        }
                    })
                    .catch(() => {
                        this.emitirSnackbar(
                            'error',
                            'Erro ao solicitar Instrumentos de Protesto'
                        );
                    })
                    .finally(() => {
                        this.loadingInstrumento = false;
                        this.solicitaInstrumento = false;
                    });
            }
        },
        habilitaDialogOferta() {
            this.$emit('habilitaDialogOferta');
        },
        verificaMensageria() {
            if (this.titulo.ds_email_devedor) {
                this.emailCheck = true;
            }
            if (this.titulo.st_email_callback == 1) {
                this.stEnvioCheck = true;
            }
            if (this.titulo.nu_telefone_devedor) {
                this.telefoneCheck = true;
            }
            this.montaInformacoes();
        },
        montaInformacoes() {
            if (this.emailCheck && this.stEnvioCheck) {
                this.alertTypeEmail = 'success';
                this.alertInfoEmail =
                    'E-mail: Título Pronto para receber mensagens de e-mail';
            }
            if (this.emailCheck && !this.stEnvioCheck) {
                this.alertTypeEmail = 'info';
                this.alertInfoEmail =
                    'E-mail: e-mail OK, porém falta ativar e-mail callback (atualmente está (0))';
            }
            if (!this.emailCheck && !this.stEnvioCheck) {
                this.alertTypeEmail = 'info';
                this.alertInfoEmail =
                    'E-mail: Atenção, título não possui e-mail cadastrado e situação de callback atualmente é (0)';
            }
            if (this.telefoneCheck) {
                this.alertTypeCel = 'success';
                this.alertInfoCel =
                    'Telefone: Título possui telefone. (Campo telefone informado)';
            }

            if (this.titulo.cd_status === 102) {
                this.alertTypeOfertas = 'success';
                this.alertInfoOfertas =
                    'Esse Título está pronto para receber ofertas na plataforma Protestado. (status 102:Protestado)';
            }

            this.checkNumberPhone();
        },
        checkNumberPhone() {
            const myString = this.titulo.nu_telefone_devedor;
            const regex = /^(?=.{10,11}$)..(99|88)/;
            const containsNumber = regex.test(myString);

            if (containsNumber) {
                this.alertTypeCelCheck = 'success';
                this.alertInfoCelCheck =
                    'Telefone: O número informado no título é um celular: pronto para receber Whatsapp/SMS (número possui 10/11 dígitos)';
            } else {
                this.alertTypeCelCheck = 'info';
                this.alertInfoCelCheck =
                    'Telefone: O Telefone informado no título não é um telefone Celular. Não poderá receber mensagens de SMS/Whatsapp';
            }
        },
        eventFecharModal,
        getDataAtualDatePicker,
        permitirLetrasNumeros,
        validarFormatoData,
        getMensagemRequest,
        checarNomeDevedorCRA(titulo) {
            if (titulo.nm_devedor_cra && titulo.nm_devedor_cra !== '') {
                return true;
            }
            return false;
        },
        checarEnderecoDevedorCRA(titulo) {
            if (
                titulo.ds_endereco_devedor_cra &&
                titulo.ds_endereco_devedor_cra !== ''
            ) {
                return true;
            }
            return false;
        },
        permitirSolicitarInstrumento() {
            if (
                !this.loadingInstrumento &&
                this.titulo &&
                !this.titulo.st_solicita_instrumento &&
                [102, 123].includes(this.titulo.cd_status)
            ) {
                return true;
            }
            return false;
        },
        getTipoAlerta() {
            const status = this.titulo?.cd_status;
            const semProtesto = !this.instrumentoProtesto;

            if (semProtesto) {
                if (![220, 221, 225, 102, 123].includes(status)) {
                    return 'red';
                }
                if ([220, 221, 225].includes(status)) {
                    return 'orange';
                }
                if ([102, 109, 123].includes(status)) {
                    return 'info'; // blue
                }
            }
            return null; //
        },

        getIconeAlerta() {
            const status_pre_protesto = [220, 221, 225];

            if (status_pre_protesto.includes(this.titulo?.cd_status)) {
                return 'mdi-clock-check-outline';
            }

            if ([102, 123].includes(this.titulo?.cd_status)) {
                if (this.solicitaInstrumento) {
                    return 'mdi-sync';
                }

                if (this.loadingInstrumento) {
                    return 'mdi-magnify';
                }

                if (this.titulo?.st_solicita_instrumento) {
                    return 'mdi-check-circle-outline';
                } else {
                    return 'mdi-information-outline';
                }
            }

            return 'mdi-close-circle-outline';
        },
        getTextoAlerta() {
            const status_pre_protesto = [220, 221, 225];

            if (status_pre_protesto.includes(this.titulo?.cd_status)) {
                return 'Instrumento de Protesto Indisponível, aguardar Protesto';
            }

            if ([102, 123].includes(this.titulo?.cd_status)) {
                if (this.solicitaInstrumento) {
                    return 'Solicitando Instrumento de Protesto...';
                }

                if (this.loadingInstrumento) {
                    return 'Buscando Instrumento de Protesto...';
                }

                if (this.titulo?.st_solicita_instrumento) {
                    return 'Solicitação de Instrumento de Protesto já realizada, favor aguardar';
                } else {
                    return 'Solicitar Instrumento de Protesto';
                }
            }

            return 'Instrumento de Protesto não está mais disponível.';
        },
        buscarInstrumentoProtesto() {
            this.loadingInstrumento = true;
            let titulo = this.titulo;
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService
                .buscarAnexoTitulo(titulo, 'IP')
                .then(
                    response => {
                        if (Object.keys(response.body).length > 0) {
                            this.instrumentoProtesto = response.body;
                        } else {
                            this.instrumentoProtesto = null;
                        }
                    },
                    error => {
                        console.log(error);
                        this.emitirSnackbar(
                            'error',
                            'Erro ao buscar Instrumento de Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.loadingInstrumento = false;
                });
        },
        buscarAnexoProtesto() {
            this.loadingAnexo = true;
            let titulo = this.titulo;
            const tituloService = new TituloService(Vue.http, this.$store);
            tituloService
                .buscarAnexoTitulo(titulo, 'AN')
                .then(
                    response => {
                        if (Object.keys(response.body).length > 0) {
                            this.anexoProtesto = response.body;
                        } else {
                            this.anexoProtesto = null;
                        }
                    },
                    error => {
                        console.log(error);
                        this.emitirSnackbar(
                            'error',
                            'Erro ao buscar Anexo do Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.loadingAnexo = false;
                });
        },
        async substituirAnexo() {
            if (this.titulo.cd_status === 220 && this.titulo.st_envio == 0) {
                try {
                    this.loadingAnexo = true;
                    const objetoAnexo = await this.montarObjetoAnexoProtesto(
                        this.titulo.ds_nosso_numero,
                        this.arquivosSelecionadosTitulo
                    );
                    const mensagemRetorno = await this.tituloService.substituirAnexo(
                        objetoAnexo
                    );
                    this.arquivosSelecionadosTitulo = null;
                    this.emitirSnackbar('green', mensagemRetorno, null);
                    this.loadingAnexo = false;
                    this.exibirDetalhe();
                    this.buscarAnexoProtesto();
                } catch (e) {
                    console.log(e);
                    const mensagem = this.getMensagemRequest(
                        e.data,
                        'Erro ao anexar Arquivo de Protesto'
                    );
                    this.loadingAnexo = false;
                    this.emitirSnackbar('error', mensagem);
                }
            } else if (this.titulo.st_envio != 0) {
                this.emitirSnackbar('error', 'Este titulo ja foi enviado');
            }
        },
        baixarInstrumentos() {
            const tituloService = new TituloService(Vue.http, this.$store);
            this.baixandoInstrumento = true;
            tituloService
                .baixarInstrumentos(this.titulo)
                .then(
                    response => {
                        const url = window.URL.createObjectURL(
                            new Blob([response.data])
                        );
                        const link = document.createElement('a');
                        const nomeDocumento = `${this.titulo.ds_nosso_numero}_instrumento_protesto.pdf`;
                        link.href = url;
                        link.setAttribute('download', nomeDocumento);
                        document.body.appendChild(link);
                        link.click();
                    },
                    error => {
                        console.log(error);
                        this.emitirSnackbar(
                            'error',
                            'Erro ao baixar Instrumento de Protesto'
                        );
                    }
                )
                .finally(() => {
                    this.baixandoInstrumento = false;
                });
        },
        verificaPermissaoAnexo() {
            if (
                this.$store.getters.isBasicUser ||
                this.$store.getters.isAdmin ||
                this.$store.getters.isSuperUser
            ) {
                return true;
            }
            return false;
        },
        verificaPermissaoCodevedores() {
            if (
                this.$store.getters.isBasicUser ||
                this.$store.getters.isAdmin ||
                (this.$store.getters.isSuperUser &&
                    !this.$store.getters.isPartner)
            ) {
                return true;
            }
            return false;
        },
        mostrarAnexoProtesto() {
            if (
                this.titulo.ds_revisao &&
                this.titulo.ds_revisao.includes('AP')
            ) {
                return true;
            }
            if (
                [220, 105, 106, 103, 121].includes(this.titulo.cd_status) &&
                this.titulo.id_anexo
            ) {
                return true;
            }
            return false;
        },
        formatarNomesArquivos() {
            if (this.arquivosSelecionadosTitulo?.length > 0) {
                const arquivos = this.arquivosSelecionadosTitulo.map(
                    arquivo => arquivo.name
                );
                return arquivos.join(', ');
            }
            return '--';
        },
        pendenteSelecionarArquivos() {
            if (
                !this.arquivosSelecionadosTitulo ||
                !this.arquivosSelecionadosTitulo.length
            ) {
                return true;
            }
            return false;
        },
        tamanhoArquivosValido(arquivos) {
            const tamanhoArquivos = arquivos.reduce(
                (acumulador, file) => acumulador + file.size,
                0
            );
            if (tamanhoArquivos > this.tamanhoMaximoSomaArquivos) {
                return false;
            }
            return true;
        },
        async anexarArquivoProtesto() {
            if (this.mostrarAnexoProtesto()) {
                try {
                    this.loadingAnexo = true;
                    const objetoAnexo = await this.montarObjetoAnexoProtesto(
                        this.titulo.ds_nosso_numero,
                        this.arquivosSelecionadosTitulo
                    );

                    const mensagemRetorno = await this.tituloService.anexarArquivo(
                        objetoAnexo
                    );
                    this.arquivosSelecionadosTitulo = null;
                    this.emitirSnackbar('green', mensagemRetorno, null);
                    this.loadingAnexo = false;
                    this.exibirDetalhe();
                    this.buscarAnexoProtesto();
                } catch (e) {
                    console.log(e);
                    const mensagem = this.getMensagemRequest(
                        e.data,
                        'Erro ao anexar Arquivo de Protesto'
                    );
                    this.loadingAnexo = false;
                    this.emitirSnackbar('error', mensagem);
                }
            }
        },
        async getExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        async baixarArquivoProtesto() {
            try {
                this.loadingAnexo = true;

                const arquivoAnexo = await this.tituloService.baixarAnexo(
                    this.titulo
                );

                const url = window.URL.createObjectURL(
                    new Blob([arquivoAnexo])
                );
                const link = document.createElement('a');
                let extensao = await this.getExtension(
                    this.anexoProtesto.ds_anexo_nome
                );
                const nomeDocumento = `${this.titulo.ds_nosso_numero}_anexo_protesto.${extensao}`;
                link.href = url;
                link.setAttribute('download', nomeDocumento);
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                console.log(e);
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao baixar Arquivo de Protesto'
                );
                this.emitirSnackbar('error', mensagem);
            } finally {
                this.loadingAnexo = false;
            }
        },
        modalAlterarAgrupador() {
            this.tituloModalConfirmacao = 'Alterar Agrupador';
            this.textoModalConfirmacao =
                'Ao alterar o Agrupador, o acesso ao título pelos usuários irá mudar. <br /><br />Deseja prosseguir?';
            this.btnConfirmarLabel = 'Confirmar';
            this.btnConfirmarModalConfirmacao = 'alterarAgrupador';
            this.mostrarModalConfirmacao = true;
        },
        modalInvalidarInstrumento() {
            this.tituloModalConfirmacao = 'Invalidar Instrumento de Protesto';
            this.textoModalConfirmacao =
                'Após invalidar um Instrumento de Protesto, ele ficará indisponível permanentemente e será necessário realizar uma nova solicitação. <br /><br />Deseja prosseguir?';
            this.btnConfirmarLabel = 'Confirmar';
            this.btnConfirmarModalConfirmacao = 'invalidarInstrumento';
            this.mostrarModalConfirmacao = true;
        },
        modalRevisaoAnexoProtesto() {
            this.tituloModalConfirmacao = 'Anexo de Protesto pendente';
            this.textoModalConfirmacao =
                '<b>Lembrete</b>: este título está em revisão e só ficará disponível para protesto após o envio do anexo de protesto. <b>Regularize</b> esta pendência na sessão "Anexo Arquivo de Protesto"';
            this.btnConfirmarLabel = 'Ok';
            this.btnConfirmarModalConfirmacao = 'scrollAnexoProtesto';
            this.mostrarModalConfirmacao = true;
            this.esconderCancelar = true;
        },
        scrollAnexoProtesto() {
            const refSessaoAnexo = this.$refs.sessaoAnexo;
            if (refSessaoAnexo) {
                refSessaoAnexo.scrollIntoView({ behavior: 'smooth' });
            }
            this.mostrarModalConfirmacao = false;
            this.esconderCancelar = false;
        },
        async invalidarInstrumento() {
            try {
                this.loading = true;
                this.mostrarModalConfirmacao = false;
                await this.tituloService.invalidarInstrumento(this.titulo);

                this.emitirSnackbar(
                    'green',
                    'Instrumento de Protesto invalidado com sucesso!',
                    null
                );
                this.exibirDetalhe();
                this.buscarInstrumentoProtesto();
            } catch (e) {
                const mensagem = this.getMensagemRequest(
                    e.data,
                    'Erro ao invalidar Instrumento de Protesto'
                );
                this.emitirSnackbar('error', mensagem);
            } finally {
                this.loading = false;
            }
        },
        emitirSnackbar(
            snackbarColor,
            mensagemSnackBar,
            snackbarIcon = 'mdi-close'
        ) {
            this.$emit(
                'showSnackbar',
                true,
                snackbarColor,
                mensagemSnackBar,
                snackbarIcon
            );
        },
        setFocus(val) {
            val.target.select();
        },
        mailTo() {
            `mailto: + ${this.cartorioEmail}`;
        },
        validarDataDigitada(isEnter = false, event = undefined) {
            const isDataValida = this.validarFormatoData(this.dataDigitavel);
            if (isDataValida) {
                if (isEnter && event) {
                    event.target.blur();
                    setTimeout(() => {
                        this.menu2 = false;
                    }, 150);
                }
                this.data = this.setDataDigitada(this.dataDigitavel);
                this.formatDate(this.data);
            } else {
                this.emitirSnackbar('error', 'Digite uma data válida');
            }
        },
        setDataDigitada(data) {
            if (!data) return null;

            const [day, month, year] = data.split('/');
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        },
        selecionarData(data) {
            this.menu2 = false;
            this.formatDate(data);
        },
        async buscaMunicipio() {
            this.loadingMunicipios = true;
            let tituloService = new TituloService(Vue.http, this.$store);
            await tituloService.getMunicipios().then(response => {
                const itensMunicipios = {};
                itensMunicipios.municipios = response.body;
                itensMunicipios.requestDate = moment();
                this.$store.commit('setItensMunicipios', itensMunicipios);
            });
            this.loadingMunicipios = false;
        },
        formatDate(data) {
            if (!data) return null;
            const [year, month, day] = data.split('-');
            this.dataApontamento = day + '/' + month + '/' + year;
            this.dataDigitavel = day + '/' + month + '/' + year;
            return `${day}/${month}/${year}`;
        },
        exibirDetalhe() {
            let titulo = this.titulo;
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService.detalharTitulo(titulo).then(
                response => {
                    const { detalhe_titulo, historico_titulo } = response.body;
                    this.$emit('atualizarTitulo', {
                        titulo: detalhe_titulo,
                        historico_titulo
                    });
                    // this.titulo = detalhe_titulo;
                    this.historicos = historico_titulo;
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        validarPreenchimentoApontamento() {
            if (
                this.novoProtocolo &&
                this.dataApontamento &&
                this.valueMunicipios &&
                this.valueCartorios
            ) {
                return true;
            }
            return false;
        },
        salvarDadosComarca() {
            if (this.validarPreenchimentoApontamento()) {
                let id_titulo = this.titulo.id_titulo;
                let protocolo = this.novoProtocolo;
                let cd_apresentante = this.titulo.cd_apresentante;
                if (this.titulo.cd_comprador) {
                    cd_apresentante = this.titulo.cd_comprador;
                }
                let data = this.dataApontamento.split('/');
                let dia = data[0];
                let mes = data[1];
                let ano = data[2];
                let dataApontamento = ano + '-' + mes + '-' + dia;
                let cartorio = this.valueCartorios;
                let municipio = this.valueMunicipios;
                return new Promise((resolve, reject) => {
                    try {
                        let tituloService = new TituloService(
                            Vue.http,
                            this.$store
                        );
                        tituloService
                            .salvarComarca(
                                protocolo,
                                dataApontamento,
                                cartorio,
                                municipio,
                                id_titulo,
                                cd_apresentante
                            )
                            .then(
                                () => {
                                    this.editarCartorio = false;
                                    // campos de texto
                                    this.novoProtocolo = null;
                                    this.dataApontamento = null;
                                    this.valueMunicipios = null;
                                    this.valueCartorios = null;
                                    // cards
                                    this.desabilitaCartorios = false;
                                    this.editarCartorioComarca = false;
                                    this.editarCartorio = false;
                                    this.emitirSnackbar(
                                        'green',
                                        'Título atualizado com Sucesso',
                                        null
                                    );
                                    this.exibirDetalhe();
                                    this.getCartorioApontamento();
                                },
                                error => {
                                    console.log(error);
                                    this.editarCartorio = false;
                                    this.desabilitaCartorios = false;
                                    this.emitirSnackbar(
                                        'error',
                                        'Erro ao realizar solicitação'
                                    );
                                }
                            );
                    } catch (e) {
                        this.mensagem = e;
                        console.log(e);
                        reject();
                    }
                });
            } else {
                this.emitirSnackbar(
                    'error',
                    'É preciso preencher todos os campos do Apontamento'
                );
            }
        },
        async ressincronizarMunicipios() {
            await this.buscaMunicipio();
        },
        async ativaBotaoCartorio() {
            this.editarCartorio = true;
            this.desabilitaCartorios = true;
            this.novoProtocolo = this.titulo.apontamento_nu_protocolo;

            if (!this.titulo.apontamento_dt_protocolo) {
                this.data = getDataAtualDatePicker();
            } else {
                this.data = this.titulo.apontamento_dt_protocolo;
            }

            this.formatDate(this.data);

            if (!this.listaMunicipios.length) {
                await this.buscaMunicipio();
            }
            this.iconLateral = true;

            if (this.titulo.apontamento_cd_municipio) {
                this.valueMunicipios = lodash.cloneDeep(
                    this.titulo.apontamento_cd_municipio
                );
                await this.buscaCartorio();

                if (this.titulo.apontamento_nu_cartorio) {
                    this.valueCartorios = lodash.cloneDeep(
                        this.titulo.apontamento_nu_cartorio
                    );
                }
            }
        },
        fechaDialogComarca() {
            this.editarCartorio = false;
            this.desabilitaCartorios = false;
            // campos de texto
            this.novoProtocolo = null;
            this.dataApontamento = null;
            this.valueMunicipios = null;
            this.valueCartorios = null;
            // cards
            this.desabilitaCartorios = false;
            this.editarCartorioComarca = false;
            this.editarCartorio = false;
        },
        salvar() {
            this.$emit('actionSalvar');
        },
        async buscaCartorio() {
            this.valueCartorios = null;
            this.editarCartorioComarca = true;
            let cidade = this.valueMunicipios;
            this.loadingCartorios = true;
            let listaCartorios = new TituloService(Vue.http, this.$store);
            await listaCartorios.getCartorios(cidade).then(response => {
                this.loadingCartorios = false;
                this.iconLateralCartorios = true;
                this.itemsCartorios = response.body;
            });
        },
        async getCartorioApontamento() {
            if (
                this.titulo.apontamento_cd_municipio &&
                this.titulo.apontamento_nu_cartorio
            ) {
                const cartorioService = new CartorioService(
                    Vue.http,
                    this.$store
                );
                const cartorioDetalhes = await cartorioService.buscarCartorio(
                    this.titulo.apontamento_cd_municipio,
                    Number(this.titulo.apontamento_nu_cartorio)
                );
                if (cartorioDetalhes && cartorioDetalhes.length > 0) {
                    this.cartorioEndereco = cartorioDetalhes[0].endereco;
                    if (this.cartorioEndereco.endsWith(',')) {
                        this.cartorioEndereco = this.cartorioEndereco.slice(
                            0,
                            -1
                        );
                    }
                    if (cartorioDetalhes[0].endereco_numero) {
                        this.cartorioEndereco +=
                            ', ' + cartorioDetalhes[0].endereco_numero;
                    }
                    if (cartorioDetalhes[0].complemento) {
                        this.cartorioEndereco +=
                            ', ' + cartorioDetalhes[0].complemento;
                    }
                    this.cartorioEmail = cartorioDetalhes[0].email;
                    this.cartorioTelefone = cartorioDetalhes[0].telefone;
                }
            }
        },
        getMascaraTelefone(telefone) {
            let mascara = '(##)####-####';
            if (telefone.length === 11) {
                mascara = '(##)#####-####';
            }
            return mascara;
        },
        async checarAgrupadores() {
            try {
                this.loadingAgrupador = true;
                await this.listarAgrupadores(this.$store, true);
            } catch (e) {
                this.emitirSnackbar(
                    'error',
                    'Erro ao buscar Agrupadores disponíveis.',
                    null
                );
            } finally {
                this.loadingAgrupador = false;
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.eventFecharModal);
    },
    async mounted() {
        this.loading = true;
        this.loadingInstrumento = true;

        if (this.$store.getters.getSuperUser == 1) {
            this.superUsuario = true;
        } else {
            this.superUsuario = false;
        }

        setTimeout(() => {
            if (this.$store.getters.isPartner == 1) {
                this.usuarioPartner = true;
            } else {
                this.usuarioRecuperi = true;
            }
            if (this.superUsuario && this.usuarioRecuperi) {
                this.getValorEmolumento();
            }

            document.addEventListener('keyup', this.eventFecharModal);

            this.verificaMensageria();
            this.getCartorioApontamento();
            this.buscarInstrumentoProtesto();

            if (this.mostrarAnexoProtesto() && this.verificaPermissaoAnexo()) {
                this.buscarAnexoProtesto();
            }

            if (this.titulo?.ds_revisao && this.titulo?.ds_revisao === 'AP') {
                this.modalRevisaoAnexoProtesto();
            }

            this.loading = false;
        }, 2500);

        await this.checarAgrupadores();
    },
    computed: {
        telefoneCartorio() {
            if (this.cartorioTelefone.length === 10) {
                return mask(this.cartorioTelefone, '(##) ####-####');
            } else {
                return mask(this.cartorioTelefone, '(##) #####-####');
            }
        },
        itensMunicipios() {
            return this.$store.getters.itensMunicipios;
        },
        listaMunicipios() {
            return this.$store.getters.listaMunicipios;
        },
        formatDocumentoSacadoInline: function() {
            return Formatter.formatDocumento(
                this.titulo.nu_identificacao_devedor,
                this.titulo.tp_identificacao_devedor
            );
        },
        formatDocumentoApresentanteInline: function() {
            return Formatter.formatDocumento(this.titulo.cnpj_sacador, '1');
        },
        // formatDocumentoApresentanteCustodiaInline: function() {
        //     return Formatter.formatDocumento(this.titulo.cnpj_sacador_custodia, '1');
        // },
        formatDataVencimentoInline: function() {
            return Formatter.formatData(this.titulo.dt_vencimento);
        },
        formatDataEmissaoInline: function() {
            return Formatter.formatData(this.titulo.dt_emissao);
        },
        formatDatacartorioInline: function() {
            return Formatter.formatData(this.titulo.apontamento_dt_protocolo);
        },
        formatCEPDevedorInline: function() {
            return Formatter.formatCEP(this.titulo.cd_cep_devedor);
        },
        getDescSituacaoComputed() {
            return this.getDescSituacao(this.titulo);
        },
        formatNmCartorio() {
            if (
                this.titulo.apontamento_nu_cartorio != null &&
                this.titulo.nm_cartorio_apontamento != null
            ) {
                return (
                    this.titulo.apontamento_nu_cartorio +
                    ' - ' +
                    this.titulo.nm_cartorio_apontamento
                );
            } else if (this.titulo.apontamento_nu_cartorio != null) {
                return this.titulo.apontamento_nu_cartorio;
            }
            return null;
        },
        formatMunicipioApontamento() {
            if (
                this.titulo.apontamento_cd_municipio != null &&
                this.titulo.nm_municipio_apontamento != null
            ) {
                return (
                    this.titulo.apontamento_cd_municipio +
                    ' - ' +
                    this.titulo.nm_municipio_apontamento
                );
            } else if (this.titulo.apontamento_cd_municipio != null) {
                return this.titulo.apontamento_cd_municipio;
            }
            return null;
        },
        agrupadores() {
            return this.$store.getters.listaAgrupadores;
        },
        usuarioAdmin() {
            if (this.$store.getters.isAdmin == 1) {
                return true;
            }
            return false;
        },
        isApresentanteCraNacional() {
            return this.$store.getters.isApresentanteCraNacional;
        },
        habilitadoNotifica() {
            return this.$store.getters.apresentanteNotifica;
        }
    }
};
</script>

<style scoped>
.text-grey {
    color: #9e9e9e;
}
</style>
